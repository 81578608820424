<template>
  <layout name="Default">
    <div class="Staff">
      <router-view />
    </div>
  </layout>
</template>

<script>
export default {
  data () {
    return {}
  }
}
</script>
